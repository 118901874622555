import React from "react"
import Layout from "../components/layout/Layout"
import ButtonLink from "../components/ui/ButtonLink"

const page404 = () => (
  <Layout>
    <h3>¯\_(ツ)_/¯</h3>
    <h1>Cette page n'existe pas.</h1>
    <p>La page que vous recherchez a été supprimée ou déplacée.</p>
    <p>
      <ButtonLink to="/">Retourner à la page d'accueil</ButtonLink>
    </p>
  </Layout>
)

export default page404
